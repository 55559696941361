<template lang="">
    <div class="content-card">
        <div class="content-card-header">
            <div class="row">
                <div class="col-12">
                    <h5 class="m-0 category-title">
                        {{ $t("lesson.ranking") }}
                    </h5>
                </div>
                <div class="col-12">
                    <div class="switch-lessson">
                        <label 
                            :class="`click-view ${filters.tab=='lesson' && 'disabled'}`" 
                            @click="handleSwitch('lesson')">
                            {{$t("lesson.title")}}   
                        </label>
                        <label 
                            :class="`click-view ${filters.tab=='teacher' && 'disabled'}`" 
                            @click="handleSwitch('teacher')">
                            {{$t("lesson.teacher")}} 
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-card-body top-content">
            <div class="row">
                <div class="col-12">
                    <div v-if="filters.tab=='teacher'">
                        <list-teacher
                            :category_name="category_name"
                        />
                    </div>
                    <div v-else>
                        <list-lesson
                            :category_name="category_name"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ListLesson from './list-lesson.vue';
import ListTeacher from './list-teacher.vue';
export default {
    components:{
        ListLesson,
        ListTeacher
    },
    data(){
        return {
            category_name: "all",
            category: "all",
            child_category: 0,
            slug_category: ["all"],
            filters: {
                tab: "lesson",
            },
            type: "rank"
        }
    },
    async created(){
        this.filters = await this.$router.getQuery(this.filters);
        if(!this.filters.tab){
            this.filters = {
                ...this.filters,
                tab: "lesson"
            }
        }
        this.handleSlugCategory();
        this.hanlderCategoryNames();
    },
    computed:{
        ...mapGetters({
            categories: "lessonCategory/getCategoryMenu",
        })
    },
    methods: {
        handleSwitch(tab){
            this.$router.setQuery({tab});
        },
        hanlderCategoryNames(){
            let { data } = this.categories;
            if(data){
                let slug_arr = this.slug_category;
                if(slug_arr[0] == 'all'){
                    this.category_name = this.$t("lesson.all_category");
                    return;
                }
                let cat = data.find(o => o.slug == slug_arr[0]);
                if(slug_arr.length == 1){
                    this.category_name = cat.name;
                }else{
                    let child_cat = cat.children.find(o => o.slug == this.category);
                    this.category_name = child_cat.name;
                }
            }
        },
        handleSlugCategory(){
            let { slug } = this.$route.params;
             let _slug = slug;
            if(typeof slug == 'string'){
               _slug = slug.split("/");
            }
            this.slug_category = _slug;
            this.category = _slug[_slug.length - 1];
            this.child_category = _slug.length > 1 ? 1 : 0;
        },
    },
    watch: {
        $route: {
            deep: true,
            handler() {
                this.handleSlugCategory();
                this.hanlderCategoryNames();
                this.filters = this.$router.getQuery(this.filters);
                if(!this.filters.tab){
                    this.filters = {
                        ...this.filters,
                        tab: "lesson"
                    }
                }
            }
        },
        categories(data, old){
            if(data != old){
                this.hanlderCategoryNames();
            }
        }
    },
}
</script>